<template>
    <div>
        <v-card flat style="background-color: var(--v-background-base) !important">
            <v-toolbar color="greyBase" dark :extended="!$vuetify.breakpoint.mobile" flat extension-height="55">
                <v-toolbar-title>
                    <b class="mr-3">Stock Lite</b>
                </v-toolbar-title>
            </v-toolbar>

            <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
                'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
                'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
            }">
                <v-card-text :style="{
                    height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                    'overflow-y': 'auto',
                }">
                    <v-tabs show-arrows v-model="activeTab" height="40px" class="mb-1 pb-1 mt-0 pt-0">
                        <v-tab v-for="tab in tabs" :key="tab" :href="`#${tab}`">
                            {{ tab }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="activeTab">
                        
                        <v-tab-item value="Dashboard">
                            <Dashboard />
                        </v-tab-item>
                        <v-tab-item value="Bookings">
                            <Bookings />
                        </v-tab-item>
                        <v-tab-item value="Booking Items">
                            <BookingItems />
                        </v-tab-item>
                        <v-tab-item value="Assign Stock">
                            <AssignStock :orders="orders" />
                        </v-tab-item>
                        <v-tab-item value="Pallet Overview">
                            <RawInventory :orders="orders" />
                        </v-tab-item>
                        
                        </v-tabs-items>
                        </v-card-text>
                        </v-card>
                        </v-card>
    </div>
</template>
<script>
import AssignStock from '../components/Stock/AssignStock.vue';
import Bookings from '../components/StockLite/Bookings.vue';
import BookingItems from '../components/StockLite/BookingItems.vue';
import Dashboard from '../components/StockLite/Dashboard.vue';
import RawInventory from '../components/Stock/OverviewTabs/RawInventory.vue';
export default {
    components: {
        Bookings,
        BookingItems,
        AssignStock,
        Dashboard,
        RawInventory
    },
    data: ()=>({
        activeTab: 0,
        loading: false,
        orders: [],
        tabs: [
            'Dashboard',
            'Bookings',
            'Booking Items',
            'Pallet Overview'
            // 'Assign Stock'
        ]
    }),
    created(){
        this.getManagedBookings()
    },
    methods: {
        async getManagedBookings(){
            this.loading = true
            this.orders = await this.$API.getOpenAssignedStockBookings({
            // params: params,
            // signal,
          });
            this.loading = false
        }
    }
}
</script>